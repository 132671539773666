<template>
  <div class="option-bu">
    <select @change="changeBu" v-model="selectBu">
      <option :value="null"></option>
      <option v-for="option in params.optionsBu" :key="option.promoBuType" :value="option.promoBuType">
        {{ option.promoBuLabel }}
      </option>
    </select>
  </div>
</template>

<script>
  import { extendWithAuthorization, http } from '@/http'
  import { sendErrorToast } from '@/utils/utils'
  export default {
    name: 'BuRenderer',
    data() {
      return {
        selectBu: null
      }
    },
    methods: {
      async changeBu() {
        this.$store.commit('common/SET_LOADING', true)
        try {
          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp.patch(`setting/bu/market/${this.params.data.rayonCode}`, {
            searchParams: { isPromoBu: Boolean(this.selectBu), promoBuType: this.selectBu }
          })
        } catch (err) {
          console.log(err)
          this.selectBu = this.params.data.promoBuType
          sendErrorToast(this.$t('setting.bu.market.message.errorBuUpdated'))
        }
        this.$store.commit('common/SET_LOADING', false)
      }
    },
    created() {
      this.selectBu = this.params.data.promoBuType
    }
  }
</script>

<style scoped>
  .v-svg {
    display: flex;
    height: 100%;
    padding: 4px;
    justify-content: center;
    vertical-align: middle;
  }
</style>
