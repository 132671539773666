<template>
  <div class="main-container">
    <h1>{{ $t('setting.bu.market.title') }} </h1>
    <div class="menu-param">
      <ElemPicker :elements="elements" :selected-element="$t('rules.market')" color="blue" @add-query="onChange" />
    </div>
    <div class="content-market">
      <ag-grid-vue
        id="myGrid"
        class="ag-theme-alpine"
        :grid-options="gridOptions"
        :column-defs="columnDefs"
        :row-data="rowData"
        @grid-ready="onGridReady"
      />
    </div>
  </div>
</template>

<script>
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-alpine.css'
  import { AgGridVue } from 'ag-grid-vue'
  import { extendWithAuthorization, http } from '@/http'
  import BuRenderer from '@/components/setting/market/grid/BuRenderer.vue'
  import { sendErrorToast } from '@/utils/utils'
  import ElemPicker from '@/components/ElemPicker.vue'

  export default {
    name: 'SettingBuMarket',
    components: {
      AgGridVue,
      BuRenderer,
      ElemPicker
    },
    data() {
      return {
        gridOptions: { defaultColDef: { resizable: true, sortable: true }, rowHeight: 30, headerHeight: 30 },
        gridApi: null,
        columnApi: null,
        columnDefs: [
          { field: 'promoBuLabel', headerName: this.$t('setting.bu.market.promoBuLabel'), width: 100 },
          { field: 'rayonCode', headerName: this.$t('setting.bu.market.rayonCode'), width: 70, sort: 'asc' },
          { field: 'rayonLabel', headerName: this.$t('setting.bu.market.rayonLabel') }
        ],
        rowData: null,
        optionsBu: [],
        elements: [
          { name: this.$t('rules.settingAssistant'), value: this.$t('rules.assistant') },
          { name: this.$t('rules.settingMarket'), value: this.$t('rules.market') }
        ]
      }
    },
    mounted() {
      this.gridApi = this.gridOptions.api
      this.gridColumnApi = this.gridOptions.columnApi
    },
    methods: {
      async onGridReady(params) {
        params.api.sizeColumnsToFit()

        try {
          const extendedHttp = await extendWithAuthorization(http)
          this.optionsBu = await extendedHttp.get(`setting/bu`).json()

          this.columnDefs[0].cellRenderer = 'BuRenderer'
          this.columnDefs[0].cellRendererParams = {
            optionsBu: this.optionsBu
          }

          this.rowData = await extendedHttp.get(`setting/bu/market`).json()
        } catch (err) {
          console.log(err)
          this.rowData = null
          sendErrorToast(this.$t('setting.bu.market.message.errorMarket'))
        }
      },
      onChange() {
        this.$router.push({
          name: 'SettingBuAssistant'
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .menu-param {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1em 0;
    .elems-container {
      width: 750px;
    }
  }
  .content-market {
    display: flex;
    flex-direction: column;
    align-items: center;
    .add-assistant {
      margin-bottom: 10px;
    }
    .ag-theme-alpine {
      height: 650px;
      width: 800px;
    }
  }
</style>
